<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs defaultActiveKey="0"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane key="0"
                    :tab="stageName"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <div class="export-download">
          <div class="button"
               @click="exportForm"
               v-if="isView">
            <img class="export"
                 :src="exportImage"
                 alt="" />
            <span>导出文档</span>
          </div>
          <div class="button"
               @click="download">
            <img class="download"
                 :src="downloadImage"
                 alt="" />
            <span>下载模板</span>
          </div>
        </div>
      </template>
    </Pane>

    <div class="container">
      <a-form :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
              :colon="false">
        <a-row>
          <a-col :lg="12"
                 :md="12"
                 :sm="24">
            <a-form-item label="当前项目"
                         class="default-highlight"
                         :label-col="{ span: 8 }"
                         :wrapper-col="{ span: 12 }">
              <a-input :disabled="true"
                       :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item label="工程名称"
                         class="default-highlight">
              <a-input :disabled="true"
                       :placeholder="projectName"></a-input>
            </a-form-item>
          </a-col>

          <a-col :lg="24"
                 :md="24"
                 :sm="24">
            <a-form-item>
              <span slot="label"
                    class="">相关附件</span>
              <div class="link-list">
                <a-checkbox-group :value="selectedFileList"
                                  @change="onSelectFile">
                  <div class="link"
                       v-for="item in fileList"
                       :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank"
                         download
                         :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>

                  <div class="control"
                       @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit"
                      type="primary"
                      v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </div>

  </div>
</template>
<script>
import { saveAs } from "file-saver";
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/design/completionReport";
import { fetchDetail } from "@/api/epc";

import FileUpload from "@/components/file-upload";
import { mapGetters } from "vuex";

export default {
  name: "designCom",
  components: {
    FileUpload,
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),

      stage: "epc_completion_report",
      isView: false,

      form: this.$form.createForm(this),
      pid: "",
      id: "", //表的id
      hid: "",
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],
    };
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid || "";
    this.id = id || "";
    this.hid = hid || "";
    this.isView = Boolean(isView) || false;

    this.getName();
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getName() {
      fetchDetail(this.pid)
        .then((res) => {
          this.projectName = res.name;
          this.designCode = res.designCode;
        })
        .catch();
    },
    getDetail() {
      fetchFileDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },

    exportForm() {
      exportFile({ id: this.id })
        .then((blob) => {
          saveAs(blob, `${this.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate()
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          if (!this.id) {
            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
